/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsersShotwireCom = /* GraphQL */ `
  query GetUsersShotwireCom($id: ID!) {
    getUsersShotwireCom(id: $id) {
      id
      username
      productName
      productPlanId
      productHistory
      groups
      maxUploads
      maxUploadTime
      maxConversionTimes
      totalUploads
      profilePhoto
      videoLogo
      licensingOffers
      followers
      following
      favorites
      downloads
    }
  }
`;
export const listUsersShotwireComs = /* GraphQL */ `
  query ListUsersShotwireComs(
    $filter: ModelUsersShotwireComFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersShotwireComs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        productName
        productPlanId
        productHistory
        groups
        maxUploads
        maxUploadTime
        maxConversionTimes
        totalUploads
        profilePhoto
        videoLogo
        licensingOffers
        followers
        following
        favorites
        downloads
      }
      nextToken
    }
  }
`;
export const getSystemStore = /* GraphQL */ `
  query GetSystemStore($id: ID!) {
    getSystemStore(id: $id) {
      id
      name
      value
      createdAt
      active
      groups
    }
  }
`;
export const listSystemStores = /* GraphQL */ `
  query ListSystemStores(
    $filter: ModelSystemStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        createdAt
        active
        groups
      }
      nextToken
    }
  }
`;
export const listSystemStoreByNameCreatedAt = /* GraphQL */ `
  query ListSystemStoreByNameCreatedAt(
    $name: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSystemStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemStoreByNameCreatedAt(
      name: $name
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        value
        createdAt
        active
        groups
      }
      nextToken
    }
  }
`;
export const getShotwireAssetsDelta = /* GraphQL */ `
  query GetShotwireAssetsDelta($id: ID!) {
    getShotwireAssetsDelta(id: $id) {
      id
      assetId
      assetType
      fileName
      fileInfo
      fileSize
      highRezFileName
      highRezDirectory
      name
      realSetId
      setDate
      companyId
      caption
      headline
      instructions
      objectName
      source
      copyright
      keywords
      byline
      fixtureId
      suppCat
      captionWriter
      city
      state
      country
      countryCode
      originalTransmissionReference
      releaseDate
      createdDate
      modified
      video_format
      video_bitrate
      video_width
      video_height
      video_fps
      video_aspect
      audio_codec
      audio_format
      audio_bitrate
      audio_rate
      audio_nch
      length
      video_frames
      video_length
      audio_length
      archived
      s3srcUrl
      proxyInfo
      apiSource
      thumbNailUrl
      mp4Outputs
      mp4Urls
      hlsUrl
      dashUrl
      cmafDashUrl
      cmafDashPlaylist
      cmafHlsUrl
      cmafHlsPlaylist
      workflowStatus
      category
      searches
      uploader
      owner
      timestamp
      license
      marketplace
      srcMediainfo
    }
  }
`;
export const listShotwireAssetsDeltas = /* GraphQL */ `
  query ListShotwireAssetsDeltas(
    $filter: ModelShotwireAssetsDeltaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShotwireAssetsDeltas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetId
        assetType
        fileName
        fileInfo
        fileSize
        highRezFileName
        highRezDirectory
        name
        realSetId
        setDate
        companyId
        caption
        headline
        instructions
        objectName
        source
        copyright
        keywords
        byline
        fixtureId
        suppCat
        captionWriter
        city
        state
        country
        countryCode
        originalTransmissionReference
        releaseDate
        createdDate
        modified
        video_format
        video_bitrate
        video_width
        video_height
        video_fps
        video_aspect
        audio_codec
        audio_format
        audio_bitrate
        audio_rate
        audio_nch
        length
        video_frames
        video_length
        audio_length
        archived
        s3srcUrl
        proxyInfo
        apiSource
        thumbNailUrl
        mp4Outputs
        mp4Urls
        hlsUrl
        dashUrl
        cmafDashUrl
        cmafDashPlaylist
        cmafHlsUrl
        cmafHlsPlaylist
        workflowStatus
        category
        searches
        uploader
        owner
        timestamp
        license
        marketplace
        srcMediainfo
      }
      nextToken
    }
  }
`;
export const listAssetsByCategory = /* GraphQL */ `
  query ListAssetsByCategory(
    $category: String
    $setDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShotwireAssetsDeltaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetsByCategory(
      category: $category
      setDate: $setDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetId
        assetType
        fileName
        fileInfo
        fileSize
        highRezFileName
        highRezDirectory
        name
        realSetId
        setDate
        companyId
        caption
        headline
        instructions
        objectName
        source
        copyright
        keywords
        byline
        fixtureId
        suppCat
        captionWriter
        city
        state
        country
        countryCode
        originalTransmissionReference
        releaseDate
        createdDate
        modified
        video_format
        video_bitrate
        video_width
        video_height
        video_fps
        video_aspect
        audio_codec
        audio_format
        audio_bitrate
        audio_rate
        audio_nch
        length
        video_frames
        video_length
        audio_length
        archived
        s3srcUrl
        proxyInfo
        apiSource
        thumbNailUrl
        mp4Outputs
        mp4Urls
        hlsUrl
        dashUrl
        cmafDashUrl
        cmafDashPlaylist
        cmafHlsUrl
        cmafHlsPlaylist
        workflowStatus
        category
        searches
        uploader
        owner
        timestamp
        license
        marketplace
        srcMediainfo
      }
      nextToken
    }
  }
`;
export const listAssetsByUploader = /* GraphQL */ `
  query ListAssetsByUploader(
    $uploader: String
    $category: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShotwireAssetsDeltaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetsByUploader(
      uploader: $uploader
      category: $category
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetId
        assetType
        fileName
        fileInfo
        fileSize
        highRezFileName
        highRezDirectory
        name
        realSetId
        setDate
        companyId
        caption
        headline
        instructions
        objectName
        source
        copyright
        keywords
        byline
        fixtureId
        suppCat
        captionWriter
        city
        state
        country
        countryCode
        originalTransmissionReference
        releaseDate
        createdDate
        modified
        video_format
        video_bitrate
        video_width
        video_height
        video_fps
        video_aspect
        audio_codec
        audio_format
        audio_bitrate
        audio_rate
        audio_nch
        length
        video_frames
        video_length
        audio_length
        archived
        s3srcUrl
        proxyInfo
        apiSource
        thumbNailUrl
        mp4Outputs
        mp4Urls
        hlsUrl
        dashUrl
        cmafDashUrl
        cmafDashPlaylist
        cmafHlsUrl
        cmafHlsPlaylist
        workflowStatus
        category
        searches
        uploader
        owner
        timestamp
        license
        marketplace
        srcMediainfo
      }
      nextToken
    }
  }
`;
export const listAssetsByMarketplaceCategory = /* GraphQL */ `
  query ListAssetsByMarketplaceCategory(
    $marketplace: String
    $category: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShotwireAssetsDeltaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetsByMarketplaceCategory(
      marketplace: $marketplace
      category: $category
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetId
        assetType
        fileName
        fileInfo
        fileSize
        highRezFileName
        highRezDirectory
        name
        realSetId
        setDate
        companyId
        caption
        headline
        instructions
        objectName
        source
        copyright
        keywords
        byline
        fixtureId
        suppCat
        captionWriter
        city
        state
        country
        countryCode
        originalTransmissionReference
        releaseDate
        createdDate
        modified
        video_format
        video_bitrate
        video_width
        video_height
        video_fps
        video_aspect
        audio_codec
        audio_format
        audio_bitrate
        audio_rate
        audio_nch
        length
        video_frames
        video_length
        audio_length
        archived
        s3srcUrl
        proxyInfo
        apiSource
        thumbNailUrl
        mp4Outputs
        mp4Urls
        hlsUrl
        dashUrl
        cmafDashUrl
        cmafDashPlaylist
        cmafHlsUrl
        cmafHlsPlaylist
        workflowStatus
        category
        searches
        uploader
        owner
        timestamp
        license
        marketplace
        srcMediainfo
      }
      nextToken
    }
  }
`;
export const listShotwireAlbums = /* GraphQL */ `
  query ListShotwireAlbums(
    $filter: ModelShotwireAlbumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShotwireAlbums(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        members
        photos {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getShotwireAlbum = /* GraphQL */ `
  query GetShotwireAlbum($id: ID!) {
    getShotwireAlbum(id: $id) {
      id
      name
      owner
      members
      photos {
        items {
          id
          albumId
          owner
          members
          bucket
          labels
          iptc
          name
        }
        nextToken
      }
    }
  }
`;
export const getShotwirePhoto = /* GraphQL */ `
  query GetShotwirePhoto($id: ID!) {
    getShotwirePhoto(id: $id) {
      id
      albumId
      owner
      members
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      preview {
        key
        width
        height
      }
      labels
      iptc
      name
      album {
        id
        name
        owner
        members
        photos {
          nextToken
        }
      }
    }
  }
`;
export const listShotwirePhotos = /* GraphQL */ `
  query ListShotwirePhotos(
    $filter: ModelShotwirePhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShotwirePhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        albumId
        owner
        members
        bucket
        fullsize {
          key
          width
          height
        }
        thumbnail {
          key
          width
          height
        }
        preview {
          key
          width
          height
        }
        labels
        iptc
        name
        album {
          id
          name
          owner
          members
        }
      }
      nextToken
    }
  }
`;
export const listPhotosByAlbum = /* GraphQL */ `
  query ListPhotosByAlbum(
    $albumId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelShotwirePhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotosByAlbum(
      albumId: $albumId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        albumId
        owner
        members
        bucket
        fullsize {
          key
          width
          height
        }
        thumbnail {
          key
          width
          height
        }
        preview {
          key
          width
          height
        }
        labels
        iptc
        name
        album {
          id
          name
          owner
          members
        }
      }
      nextToken
    }
  }
`;
